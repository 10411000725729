import React, { Component } from "react"

export const Context = React.createContext()

class GlobalContext extends Component {
  state = {
    style: "light",
  }

  toggleTheme = () =>
    this.setState(({ style }) => ({
      style: style === "light" ? "dark" : "light",
    }))

  render() {
    return (
      <Context.Provider
        value={{
          ...this.state,
          toggleTheme: this.toggleTheme,
        }}
      >
        {this.props.children}
      </Context.Provider>
    )
  }
}

export default GlobalContext
